import { Trans, defineMessage, t } from "@lingui/macro"

import { useLingui } from "@lingui/react"
import { StaticImage } from "gatsby-plugin-image"
import { useLocalization } from "gatsby-theme-i18n"
import React, { useEffect, useState } from "react"
import { Seo } from "../components/seo"
import { BaseLayout } from "../layouts/base-layout"

const appleRefLinks = {
  homepage:
    "https://apps.apple.com/app/apple-store/id1603039736?pt=123481498&ct=homepage&mt=8",
  producthunt:
    "https://apps.apple.com/app/apple-store/id1603039736?pt=123481498&ct=producthunt&mt=8",
  reddit:
    "https://apps.apple.com/app/apple-store/id1603039736?pt=123481498&ct=reddit&mt=8",
  instagram:
    "https://apps.apple.com/app/apple-store/id1603039736?pt=123481498&ct=instagram&mt=8",
  linkedin:
    "https://apps.apple.com/app/apple-store/id1603039736?pt=123481498&ct=linkedin&mt=8",
  ycombinator:
    "https://apps.apple.com/app/apple-store/id1603039736?pt=123481498&ct=ycombinator&mt=8",
}

const googleRefLinks = {
  homepage:
    "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&utm_source=homepage",
  producthunt:
    "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&utm_source=producthunt",
  reddit:
    "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&utm_source=reddit",
  instagram:
    "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&utm_source=instagram",
  linkedin:
    "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&utm_source=linkedin",
  ycombinator:
    "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&utm_source=ycombinator",
}

async function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const photoTypes = [
  {
    label: defineMessage({ message: "profile photo?" }),
    color: "primary",
  },
  {
    label: defineMessage({ message: "dating photo?" }),
    color: "dating",
  },
  {
    label: defineMessage({ message: "social photo?" }),
    color: "social",
  },
  {
    label: defineMessage({ message: "business photo?" }),
    color: "business",
  },
]

function PhotoType() {
  const { i18n } = useLingui()
  const [photoTypeIdx, setPhotoTypeIdx] = useState(0)
  const photoType = photoTypes[photoTypeIdx]

  const [photoTypeText, setPhotoTypeText] = useState(i18n._(photoType.label))

  useEffect(() => {
    const interval = setTimeout(async () => {
      const newIdx =
        photoTypeIdx === photoTypes.length - 1 ? 0 : photoTypeIdx + 1

      let currentText = i18n._(photoTypes[photoTypeIdx].label)

      const newPhotoType = photoTypes[newIdx]

      while (currentText.length > 0) {
        currentText = currentText.slice(0, -1)
        await delay(50)
        setPhotoTypeText(currentText)
      }

      setPhotoTypeIdx(newIdx)

      for (let i = 1; i <= i18n._(newPhotoType.label).length; i++) {
        currentText = i18n._(newPhotoType.label).slice(0, i)
        await delay(50)
        setPhotoTypeText(currentText)
      }
    }, 3000)

    return () => clearInterval(interval)
  }, [i18n, photoTypeIdx, setPhotoTypeIdx, setPhotoTypeText])

  return <span className={`text-${photoType.color}`}>{photoTypeText}</span>
}

const IndexPage = (props) => {
  const [ref, setRef] = useState("homepage")
  const { locale } = useLocalization()

  useEffect(() => {
    const ref =
      new URLSearchParams(window?.location?.search).get("ref") ?? "homepage"

    setRef(ref)
  }, [])

  const appleAppLink = appleRefLinks[ref] ?? appleRefLinks.homepage
  const googleAppLink = googleRefLinks[ref] ?? googleRefLinks.homepage

  return (
    <BaseLayout>
      <Seo
        title="Testframe"
        description={t`Find your best profile photo. Whether you're testing professional, social, or dating profile picture, we can help you to make the right impression.`}
        pathname={props.location.pathname}
      />
      <div className="md:flex justify-between items-center">
        <div className="flex-1">
          <h1 className="text-4xl font-extrabold">
            <Trans>How to find best</Trans> <PhotoType />
          </h1>
          <ul className="text-xl font-bold">
            <li className="mt-3">
              <span className="line-through">
                <Trans>Ask friends</Trans>
              </span>
            </li>
            <li className="mt-3">
              <span className="line-through">
                <Trans>Hire expert</Trans>
              </span>
            </li>
            <li className="mt-3">
              <span className="line-through">
                <Trans>Try by yourself</Trans>
              </span>
            </li>
            <li className="mt-3">
              <span className="line-through">
                <Trans>AI</Trans>
              </span>
            </li>
            <li className="mt-3">
              <span>
                <Trans>Statistics - Let other people rate your photo</Trans>
              </span>
            </li>
          </ul>
          <h2 className="text-2xl font-bold mt-3">
            <Trans>Testframe is a large photo rating network.</Trans>
          </h2>
          <h2 className="text-2xl font-bold mt-3">
            <Trans>
              Test &amp; Rate your dating, social and business photos with
              Testframe!
            </Trans>
          </h2>
          <div className="flex flex-1 lg:w-4/6 items-center justify-between mt-3">
            <a
              href={appleAppLink}
              target="_blank"
              rel="noreferrer"
              className="flex-1"
            >
              <StaticImage
                src="../images/app-store-badge-black.png"
                alt={t`Download on the App Store`}
              />
            </a>
            <a
              href={googleAppLink}
              target="_blank"
              rel="noreferrer"
              className="flex-1 ml-6"
            >
              <StaticImage
                src="../images/google-play-badge.png"
                alt={t`Get it on Google Play`}
              />
            </a>
          </div>
        </div>
        <div id="cf4a" className="mt-3 md:mt-0 flex-1 flex justify-center">
          {locale === "en" && (
            <>
              <StaticImage
                src="../images/main-4.en.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-3.en.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-2.en.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-1.en.png"
                alt="Testframe"
                width={360}
              />
            </>
          )}
          {locale === "de" && (
            <>
              <StaticImage
                src="../images/main-4.de.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-3.de.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-2.de.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-1.de.png"
                alt="Testframe"
                width={360}
              />
            </>
          )}
          {locale === "ru" && (
            <>
              <StaticImage
                src="../images/main-4.ru.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-3.ru.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-2.ru.png"
                alt="Testframe"
                width={360}
              />
              <StaticImage
                src="../images/main-1.ru.png"
                alt="Testframe"
                width={360}
              />
            </>
          )}
        </div>
        {/* <span className="text-primary">aaa</span>
        <span className="text-dating">aaa</span>
        <span className="text-business">aaa</span>
        <span className="text-social">aaa</span> */}
      </div>
    </BaseLayout>
  )
}

export default IndexPage
